.mask{
  position: fixed;
  left:0;
  right: 0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: rgba($color: #000000, $alpha: .6);
  z-index: 100000;
  transition: .4s;
}
#root{
  z-index: 2;
  position: relative;
}
.message{
  display: flex;
  align-items: center;
  padding:15px;
  box-shadow: 0 4px 14px rgba(90, 90, 90, 0.1);    
  background: #fff;
  min-width: 260px;
  border-radius: 5px;
  max-width: 300px;
  animation: sliderUp ease-out .3s;
  &-title{
    font-size: 15px;
    margin:0 0 10px;
  }
  &-content{
    color: #888;
    font-size:12px;
    line-height: 1.5;
  }
  .ico{
    font-size:28px;
    padding: 20px 20px 20px 10px;
    color: blue;
  }
  &-box{
    position: fixed;
    right:50px;
    top:80px;
    z-index: 3;
  }
  .close{
    position: absolute;
    right:0;
    top:0;
    padding: 20px;
    cursor: pointer;
    &:hover{
      opacity: .8;
    }
  }
}
@keyframes scale{
  0%{
    transform: scaleY(0);
    opacity: 0;
  }
  50%{
    opacity: 1;
    transform: scaleY(1.3);
  }
  to{
    transform: scaleY(0);
    opacity: 0;
  }
}
@keyframes zoomScale {
   0%{
    transform: scale(0.9);
  }
  50%{
    transform: scale(1.1);
  }
  to{
    transform: scale(0.9);
  }
}
.page{
  &-load{
    position: fixed;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-loading{
    width: 60px;
    height: 60px;
    background-size: 100%;
    background-repeat: no-repeat;
    animation: zoomScale 1s ease-in infinite;
  }
}
.loading{
  display: flex;
  .item{
    margin: 0 2px;
    width: 4px;
    height: 14px;
    background: #fff;
    font-size: 0;
    animation: scale 1.2s infinite backwards;
    &.i-0{
      animation-delay: 0;
    }
    &.i-1{
      animation-delay: .3s;
    }
    &.i-2{
      animation-delay:.6s;
    }
     &.i-3{
      animation-delay:.9s;
    }
  }
    &.mask{
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    top:0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
  
    .item{
      background: blue;
    }
  }
  &.small{
    .item{
      width: 3px;
      height: 8px;
    }
  }
}
.modal{
  background: #fff;
  padding:10px 20px 10px;
  border-radius: 4px;
  width: 430px;
  position: relative;
  animation: sliderUp .5s ease;
    &-wrap{
      z-index: 100001;
      position: fixed;
      background: transparent;
      left:0;
      top:0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  &-close{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    &:hover{
      opacity: .5;
    }
  }
  &-title{
    font-size: 16px;
    padding:20px 0 30px;
    margin:0;
  }
}

@media screen and (max-width:600px){
  .modal{
    width: 90%;
    margin: 0 auto;
  }
  .fixed {
    .modal{
      position: absolute;
      left:0;
      right:0;
      bottom:0;
    }
  }
}